import React from 'react'
import Layout from '../Layout/Layout'
const About = () => {
    return (
        <>
            <Layout>

            </Layout>
        </>
    )
}

export default About
